.icon-container {
  position: relative;
  display: inline-block;
  padding: 4px;
}

.icon-container::before,
.icon-container::after {
  content: '';
  position: absolute;
  border: 1px solid #0038b3;
}

.icon-container::before {
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border-right: none;
  border-bottom: none;
}

.icon-container::after {
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-left: none;
  border-top: none;
}