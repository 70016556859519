@import '~react-leaflet-markercluster/dist/styles.min.css';
@import '~antd/dist/antd.css';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}

p {
  font-size: 16px;
}

h1,
h2,
h3 {
  margin-bottom: 6px;
  margin-top: 6px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

.flex-row {
  display: flex;
  position: relative;
  margin-left: -12px;
  margin-right: -12px;
}

.flex-row>* {
  flex: 1;
  width: 0;
  flex-basis: 600px;
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
}

.rbc-event.rbc-event {
  padding: 4px 8px;
  background-color: #123fbf;
}

#root {
  height: 100%;
  display: flex;
  overflow: 'hidden';
  position: 'fixed';
}

#root>section.ant-layout {
  display: flex;
}

.ant-popover-content {
  max-width: 500px;
}

.ant-layout-content {
  display: table;
}

.skiptranslate {
  display: none;
}

#google_translate_element>.skiptranslate {
  display: contents;
}

.cursor-pointer {
  cursor: pointer;
}