.custom-multi-select .ant-select-selection__rendered li[title="Minor"] {
  background-color: #DEC431;
  color: white;
  border-radius: 4px;
}

.custom-multi-select .ant-select-selection__rendered li[title="Major"] {
  background-color: #E28931;
  color: white;
  border-radius: 4px;
}

.custom-multi-select .ant-select-selection__rendered li[title="Critical"] {
  background-color: #FF0000;
  color: white;
  border-radius: 4px;
}

.custom-multi-select .type-critical {
  background-color: #FF0000;
  color: white;
  border-radius: 4px;
}

.custom-multi-select .ant-select-selection__choice__remove {
  display: none !important;
}

.custom-multi-select .ant-select-selection__choice {
  padding-right: 10px !important;
}

/* ;;; */

.custom-option-critical .custom-text {
  background-color: #f5222d;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
}

.custom-option-major .custom-text {
  background-color: #fa8c16;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
}

.custom-option-minor .custom-text {
  background-color: #d4b106;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
}

.custom-multi-select .ant-select-selection__choice__content {
  padding-left: 8px;
}

.ant-select-selection__choice.custom-tag-critical {
  background-color: #f5222d;
  color: white;
}

.custom-multi-select .ant-select-selection__choice.custom-tag-major {
  background-color: #fa8c16;
  color: white;
}

.custom-multi-select .ant-select-selection__choice.custom-tag-minor {
  background-color: #d4b106;
  color: white;
}

.custom-multi-select .ant-select-selection__choice {
  border: none !important;
}

.custom-multi-select .ant-select-selection__choice__content .custom-text {
  padding-right: 10px !important;
}

.custom-multi-select .ant-select-selected-icon {
  display: none !important;
}

.ant-select-selection__rendered {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: auto;
}